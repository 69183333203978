import React from 'react';
import { mq, theme } from "../../theme"
import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";
import { FunctionalComponentProps } from "../../global";
import { Text } from "../Text/Text";
import CheckIcon from "../../images/icons/check.svg";
import Button, { EButtonType } from '../Button';
import InfoIcon from "../../images/icons/info-icon.svg";
import { Link } from 'gatsby';

export type SectionItem = {
  title: string;
  info?: React.ReactNode | React.ReactNode;
}

export type Section = {
  children?: React.ReactNode | React.ReactNode[];
  title: string;
  titleIcon: React.ReactNode;
  items: SectionItem[];
}

type Props = FunctionalComponentProps & {
  logo?: React.ReactNode;
  color?: string;
  productTitle: string;
  productSubtitle: string;
  maximumDiscount?: number;
  maximumDiscountInfoChildren?: React.ReactNode | React.ReactNode[];
  sections: Section[];
  priceDiscountedLine1: string;
  priceDiscountedLine2: string;
  priceTitle: string;
  buttonTitle: string;
  buttonLink: string;
  radiusLeft?: boolean;
  radiusRight?: boolean;
}

const Product = ({
    logo,
    color = theme.colors.primary,
    productTitle,
    productSubtitle,
    maximumDiscount,
    maximumDiscountInfoChildren,
    sections = [],
    priceDiscountedLine1,
    priceDiscountedLine2,
    priceTitle,
    buttonTitle,
    buttonLink,  
    radiusLeft,
    radiusRight,
    ...rest
  }: Props
) => {
  return <div
    css={{
      boxShadow: '-9px 20px 41px -21px rgba(0,0,0,0.75)',
    }}
    {...rest}
  >
    {
      maximumDiscount && (
        <div 
          css={{ 
            backgroundColor: `${color}60`,
            borderTopLeftRadius: radiusLeft ? 6 : undefined,
            borderTopRightRadius: radiusRight ? 6 : undefined,
            paddingTop: 8,
            paddingBottom: 8
          }}
        >
          <Text 
            center
            onDark
            block
          >
            Maximale voordeel
          </Text>
          <Text 
            center
            onDark
            block
            css={{
              fontWeight: 700
            }}

          >
            € {maximumDiscount} ,-
            <a
              data-for="main"
              data-tip={maximumDiscountInfoChildren}
              data-html={true}
            >
              <InfoIcon 
                css={{
                  position: 'absolute',
                  right: 6,
                }}
              />
            </a>
          </Text>
        </div>
      )
    }
    <div    
      css={(theme: any) => ({ 
        backgroundColor: theme.colors.white_accent,
        overflow: 'hidden',
      })}
    >
      <div 
        css={{
          backgroundColor: color,
          paddingTop: 8,
          paddingBottom: 8
        }}
      >
        {
          logo && (
            <Text 
              onDark
              block
              center
            >
              {logo}
            </Text>
          )
        }
        <Text 
          size="regular"
          onDark
          block
          center
        >
          {productTitle}
        </Text>
        <Text 
          size="large"
          onDark
          block
          center
        >
          {productSubtitle}
        </Text>
      </div>
      { sections.map((section: Section) => {
        return (
          <div css={{
            marginTop: 8
          }}>
            <Text 
              center
              block
              css={{
                fontWeight: 'bold',
                paddingTop: 8,
                paddingBottom: 8,
                marginBottom: 8,
                backgroundColor: '#e2e2e2',
              }}
            >
              <span css={{ marginRight: 8 }}>{section.titleIcon}</span>{section.title}
            </Text>
            {section.children}
            {(section.items || []).map((item: SectionItem) => {
              return <div
                css={{
                  display: 'grid',
                  gridTemplateColumns: 'auto 1fr auto',
                  gridGap: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  marginBottom: 4
                }}
              >
                <CheckIcon css={(theme: any) => ({ "#Desktop":{ fill: color } ,marginTop: 6, visibility: item.notincluded===true?'hidden':'inherit' })}/>
                <div css={{  textDecoration: item.notincluded===true ?'line-through':'none'}}>{item.title}</div>
                {item.info && (
                  <a
                    data-for="section"
                    data-tip={item.info}
                    data-html={true}
                  >
                    <InfoIcon 
                      css={{
                        'path': {
                          fill: color
                        }
                      }}
                    />
                  </a>
                )}
              </div>
            })}
            <div 
              css={(theme: any) => ({
                pointerEvents: 'none',
                fontSize: 12,
                maxWidth: '90%',
                margin: '0 auto',
                color: theme.colors.black,
                overflow: 'hidden',
                letterSpacing: 3
              })}
            >
              .......................................................................
            </div>
          </div> 
        )
      })}   
      <div>
        <Link
          to={buttonLink}
        >
          <Button 
            css={mq({
              fontSize: 14,
              height: 30,
              fontWeight: 600,
              margin: '16px auto 16px',
              backgroundColor: color,
            })} 
            type={EButtonType.GREEN}
            callToAction
          >
            {buttonTitle}
          </Button>
        </Link>
      </div>
      <div 
        css={{
          paddingTop: 8,
          paddingBottom: 8,
          backgroundColor: color,
        }}
      >
        <Text 
          onDark
          block
          center
        >
          {priceDiscountedLine1}
        </Text>
        <Text 
          css={{
            fontWeight: 'bold'
          }}
          size="medium"
          onDark
          block
          center
        >
          {priceDiscountedLine2}
        </Text>
      </div>
      <Text 
        block
        center
        css={{
          paddingTop: 8,
          paddingBottom: 8,
        }}
      >
        {priceTitle}
      </Text>
    </div>
  </div>
};

export {
  Product
}