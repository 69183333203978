
import React from 'react';
import { useMediaQuery } from 'react-responsive'
import Carousel from 'react-multi-carousel';
import { ProductZiggo } from '../ProductZiggo/ProductZiggo';
import { packages } from '../../lib/packages';
import { mq } from '../../theme';
// @ts-ignore
import Slide from 'react-reveal/Slide';
import ReactTooltip from 'react-tooltip';

const ProductSection = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 920px)'
  });
  const [showShadow, setShowShadow] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setShowShadow(true);
    }, 1500)
  }, [])
  
  return (
    <>
      <ReactTooltip
        id="main"
        html={true}
      />
      <ReactTooltip
        id="section"
        html={true}
      />
      <Carousel
        key={isDesktop ? 1 : 0}
        css={mq({
          display: 'grid',
          gridTemplateColumns: ['repeat(6, minmax(0, 1fr))', 'repeat(6, minmax(0, 1fr))', 'repeat(6, minmax(0, 1fr))'],
          position: 'relative',
          zIndex: 1,
          maxWidth: 860,
          margin: '0 auto',
          marginTop: 32,
          boxShadow: showShadow ? '-2px 10px 41px -21px rgba(0,0,0,0.75)' : undefined,
        })}
        responsive={{
          desktop: {
            breakpoint: { max: 120000, min: 920 },
            items: 3,
          },
          tablet: {
            breakpoint: { max: 920, min: 380 },
            items: 2,
            paritialVisibilityGutter: 30,
          },
          mobile: {
            breakpoint: { max: 380, min: 0 },
            items: 1,          
            paritialVisibilityGutter: 30,
          }
        }}
        ssr
        partialVisible={isDesktop ? false : true}
        // infinite={isDesktop ? false : true}
        showDots={false}
        arrows={false}
        containerClass="first-carousel-container container"
        deviceType={'desktop'}
      >
        {packages.map((_package, index) => {
          return (
            <Slide 
              ssrFadeout
              bottom
              delay={100 + (index+1) * 300}
              duration={1250}
              when={true}
              appear={true}
            >
              <ProductZiggo              
                color={_package.color}
                maximumDiscount={_package.maximumDiscount}
                maximumDiscountInfoChildren={_package.maximumDiscountInfoChildren}
                priceDiscountedLine1={_package.priceDiscountedLine1}
                priceDiscountedLine2={_package.priceDiscountedLine2}
                priceTitle={_package.priceTitle}
                productTitle={_package.productTitle}
                radiusLeft={index === 0}
                radiusRight={index === _package.length-1}
                internetSpeed={_package.internetSpeed}
                internetItems={_package.internetItems}
                // tvKabelItems={_package.tvKabelItems}
                tvDigitalItems={_package.tvDigitalItems}
                phoneItems={_package.phoneItems}
                products={_package.products}
              />
            </Slide>
          )
        })}     
      </Carousel>
    </>
  );
}

export {
  ProductSection
}