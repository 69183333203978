import React from 'react';
import { FunctionalComponentProps } from '../../global';
import { mq } from '../../theme';
import { Product, SectionItem } from "../Product/Product"
import ReactSpeedometer from "react-d3-speedometer";
import ReactTooltip from 'react-tooltip';
import ZiggoIcon from "../../images/icons/ziggo.svg";
import TVIcon from "../../images/icons/tv-icon.svg";
import PhoneIcon from "../../images/icons/phone-icon.svg";
import InternetIcon from "../../images/icons/wifi-icon.svg";

type Props = FunctionalComponentProps & {
  color?: string;
  productTitle: string;
  radiusLeft?: boolean;
  radiusRight?: boolean;
  internetSpeed?: number;
  internetItems: SectionItem[];
  tvKabelItems: SectionItem[];
  tvDigitalItems: SectionItem[];
  phoneItems: SectionItem[];
  products: any;
}

const ProductZiggo = ({ 
  productTitle, 
  radiusLeft, 
  radiusRight, 
  color, 
  maximumDiscount,
  maximumDiscountInfoChildren,
  priceDiscountedLine1,
  priceDiscountedLine2,
  priceTitle,
  internetSpeed, 
  internetItems,
  tvKabelItems,
  tvDigitalItems,
  phoneItems,
  products,
  ...rest
}: Props) => {
  return (
    <>
      <Product 
        // css={mq({
        //   marginLeft: [6, null, 0],
        //   marginRight: [6, null, 0],
        // })}
        logo={
          <ZiggoIcon
            css={{ 
              height: 18,
              marginBottom: 4,
              width: 'auto',
              'path': {
                fill: '#fff'
              }
            }} 
          />
        }
        radiusLeft={radiusLeft}
        radiusRight={radiusRight}
        color={color}
        productTitle="Alles-in-1"
        productSubtitle={productTitle}
        buttonTitle="Sluit nu direct af"
        buttonLink={`/pakketten?i=${products.internet}&t=${products.tv}&p=${products.phone}&e=${products.extra}`}
        maximumDiscount={maximumDiscount/100}
        maximumDiscountInfoChildren={maximumDiscountInfoChildren}
        priceDiscountedLine1={priceDiscountedLine1}
        priceDiscountedLine2={priceDiscountedLine2}
        priceTitle={priceTitle}
        sections={[
          {
            title: "Internet",
            titleIcon: <InternetIcon />,
            children: <div
              css={{
                marginTop: -12,
                'svg': {
                  display: 'block',
                  margin: '0 auto',
                }
              }}
            >
              <ReactSpeedometer
                width={200}
                height={130}
                needleHeightRatio={0.7}
                value={internetSpeed}
                maxValue={1000}
                currentValueText=" "
                segmentColors={["#15B5E2", "#15B5E2", "#35DCC2", "#35DCC2", "#686868"]}
                segments={6}
                maxSegmentLabels={0}
                ringWidth={4}
                needleTransitionDuration={2500}
                needleTransition="easeElasticIn"
                needleColor={"#686868"}
                startColor={"#15B5E2"}
                endColor={"#35DCC2"}
                textColor={"#686868"}
              />
            </div>,
            items: internetItems,
          },
          {
            title: "TV - kabel",
            titleIcon: <TVIcon />,
            items: tvKabelItems,
          },
          {
            title: "TV - digitaal",
            titleIcon: <TVIcon />,
            items: tvDigitalItems,
          },
          {
            title: "Bellen",
            titleIcon: <PhoneIcon />,
            items: phoneItems,
          }
        ]}
        {...rest}
      />
    </>
  )
}

export {
  ProductZiggo
}