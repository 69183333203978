
import { faq } from '../../lib/faq';
import { mq } from '../../theme';
import Button, { EButtonType } from '../Button';
import HrefList from '../HrefList';
import { Text } from '../Text/Text';

const FAQSection = () => {
    const faqItems = faq.filter( item => { return item.homepage == 1} )
    .map(item =>{ 
        return {
            text: item.question,
            href: '/faq/'+item.slug
        }
    })
    return (
      <div
        css={mq({
          maxWidth: 680,
          width: '100%',
          margin:'auto',
          marginTop: 90,
          paddingLeft: 8,
          paddingRight: 8,
        })}
      >
        <Text
          size="xl"
          css={{
            fontWeight: 500,
            textAlign:'center',
            display: 'block',
            marginBottom: 32,
            marginTop: 32,
          }}
        >
          Veel gestelde vragen
        </Text>
        <HrefList
            items={faqItems}
        />
        <Button css={mq({
          margin: ['10px auto 0',null,'10px 0 0 auto'],
          })} onClick={() => window.location.href="/faq/veelgesteldevragen"} type={EButtonType.BLACKONWHITE}>Bekijk alle vragen</Button>
      </div>
    );
  }
  
  export default FAQSection;