
import React from 'react';

// @ts-ignore
import Fade from 'react-reveal/Fade';

import { mq } from '../../theme';
import { BackgroundOverlay } from '../BackgroundOverlay/BackgroundOverlay';
import { BackgroundVideo } from '../BackgroundVideo/BackgroundVideo';
import ZiggoVodafoneLogo from '../../images/fotos/vodafoneziggo-white.png';
import { Text } from '../Text/Text';
import { TrustBox } from '../TrustBox/TrustBox';

const VideoHeroSection = ({ video }: any) => {
  return (
    <>
      <section
        css={mq({
          display: 'grid',
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
          gap: 24,
          alignItems: 'end',
          position: 'relative',
          maxWidth: 860,
          margin: '0 auto',
          zIndex: 1,
          paddingTop: 140,
          paddingLeft: 12,
          paddingRight: 12,
        })}
      >
        <div>
          <div            
            css={{
              position: 'absolute',
              top: 10,
              left: 0,
              color: '#fff',
            }}
          >
            <Text onDark css={{ marginLeft: 14, opacity: 0.6 }} size="regular">Partner van:</Text>
            <br />
            <img
              src={ZiggoVodafoneLogo} 
            />
          </div>
          <Fade 
            ssrFadeout
            duration={1250}
            when={true}
            appear={true}
          >
            <Text 
              onDark
              block
              size='xl'
            >
              Welkom bij
            </Text>
            <Text 
              css={(theme: any) => mq({
                color: theme.colors.product_colors.start,
                width:"44vw"
              })}
              size='xl'
            >
              Smart Sales Company
            </Text>
            <Text 
              onDark
              block
              size='large'
              css={{
                marginTop: 8
              }}
            >
              Uw partner op het gebied van <br/> persoonlijk telecom advies
            </Text>
          </Fade>
        </div>
        
      </section>
      <BackgroundOverlay
        css={mq({
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          height: 660,
        })}
      >
        <BackgroundVideo 
          css={mq({
            height: 660,
          })}
          posterUrl={video.videoScreenshots[0].publicURL}
          video={{
            H264: video.videoH264,
            H265: video.videoH265,
            VP9: video.videoVP9
          }}
        />
      </BackgroundOverlay>
    </>
  )
}

export {
  VideoHeroSection
}