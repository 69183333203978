
import { mq, theme } from "../../theme";
import Button, { EButtonType } from "../Button";
import PhoneIcon from "../../images/icons/phone.svg";
import { Text } from "../Text/Text";

const ContactSection = () => {
   
    return (
      <div css={{ 
        backgroundColor:theme.colors.primary,
        marginTop:'100px',
      }}>
        <div css={mq({
          maxWidth: 600,
          width: '100%',
          color:theme.colors.white,
          fontWeight: 800, 
          margin:'auto',
          display:'flex',
          flexDirection: ['column', null, 'row'],
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: 60,
          paddingBottom: 80,
        })}
        >
          <div
            css={{
              display:'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Text
              size="xl"
              onDark
              css={{
                fontWeight: 500,
                display: 'block',
                marginBottom: 8,
              }}
            >
              Heeft u nog vragen?
            </Text>
            
            <Text
              size="medium"
              onDark
              css={{
                fontWeight: 500,
              }}
            >
              Laat het ons weten. We staan voor u klaar
            </Text>
          </div>
          <div css={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center',
            }}>         
            <Button css={mq({
              margin: ['10px auto 30px',null,'10px 0 0 auto'], fontSize:16,
              })} onClick={() => alert('klik')} type={EButtonType.WHITE} callToAction callToActionIcon={<PhoneIcon css={{height: 16, marginRight: 4, top: 2}}/>} >
                030 249 8181
            </Button>
          </div>  
        </div>  
      </div>
    );
  }
  
  export default ContactSection;