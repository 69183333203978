import { graphql, PageProps } from 'gatsby';
import Layout from '../layout';
import FAQSection from '../components/FAQSection';
import ContactSection from '../components/ContactSection';
import ReviewSection from '../components/ReviewSection';
import './indexPage.css';
import backgroundImg from "../images/fotos/RepeatBg.png";
import Helmet from "react-helmet"
// @ts-ignore
import Fade from 'react-reveal/Fade';
import React from 'react';

import "react-multi-carousel/lib/styles.css";
import { ProductSection } from '../components/ProductSection/ProductSection';
import { VideoHeroSection } from '../components/VideoHeroSection/VideoHeroSection';

class IndexPage extends React.PureComponent<PageProps<{ file: any }>, {}> {
  public render() {
    const video = this.props.data.file;    
    return (
      <Layout>
        <div 
          css={{
            position: 'relative', 
            minHeight: 660,
            paddingBottom: 235,
            backgroundImage: `url('${backgroundImg}')`
          }}
        >
          <VideoHeroSection 
            video={video}
          />
          <ProductSection />
        </div>
        {/*<ReviewSection />*/}
        <FAQSection />
        <ContactSection />
      </Layout>
    );
  }
}


export const query = graphql`
  query HomePageQuery {
    file(relativePath: { eq: "video/vodafone-video.mp4" }) {
      id
      name
      videoH264 {
        path
      }
      videoH265 {
        path
      }
      videoVP9 {
        path
      }
      videoScreenshots(timestamps: ["0", "1", "50%", "99%"]) {
        publicURL
      }
    }
  }
`;

export default IndexPage;
