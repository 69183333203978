import { Link } from "gatsby";
import { mq } from "../../theme";

type HrefListItem = {
    text: string;
    href: string;
}

type HrefListProps = {
    items: HrefListItem[];
}

const renderListItem = (item: HrefListItem) => {
    return <div>
            <Link 
                css={(theme: any) => mq({
                    color: 'inherit',
                    textDecoration: 'none',
                    display: 'block',
                    borderBottom: '1px solid #c5c5c5',
                    paddingLeft: 4,
                    paddingTop: 12,
                    paddingBottom: 12,
                    fontWeight: 800,
                    fontSize:18,
                    ':hover': {
                      textDecoration: 'underline'
                    }
                })}
                to={item.href}
            >
                {item.text}
            </Link>
        </div>
}

const HrefList = (props: HrefListProps) => {
    return <div
        css={{
            '> div:first-child a': {
                borderTop:'1px solid #c5c5c5',
            }
        }}
    >
        {props.items.map(item => renderListItem(item))}
    </div>
}

export default HrefList